import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractReportService } from '../admin-panel/report/domain/services/ireport.service';
import { Report } from '../admin-panel/report/domain/entities/report';
import { Summary } from '../admin-panel/report/domain/entities/summary';
import { SASToken } from '../admin-panel/report/domain/entities/sas';
import { AbstractTokenService } from '../admin-panel/report/domain/services/itoken.service';
import { environment } from 'src/environments/environment';
import { AbstractBlobService } from '../admin-panel/report/domain/services/iblob.service';
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import * as pdfMake from 'pdfmake/build/pdfmake';
import { AbstractDashboardService } from '../admin-panel/dashboard/domain/services/idashboard.service';

declare var getDocumentDefinitionForSTEMI: (t: any, i: any, s: any, r: any, a: any) => Promise<any>

@Component({
  selector: 'app-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.css']
})
export class SummaryPageComponent implements OnInit {

  @Input() title?: string;
  recordingId?: number
  recording!: Report

  constructor(private _router: Router,
    private abstarctService: AbstractReportService,
    private _activatedRoute: ActivatedRoute,
    private _tokenService: AbstractTokenService,
    private _blobService: AbstractBlobService,
    private _dashboardService: AbstractDashboardService
  ) { }

  ngOnInit(): void {

    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

    this._dashboardService.getReadTokenForJSPDF().subscribe((res) => {
      const STEMIGeneratorJSFile = environment.azureBaseUrl + 'pdf-generator/' + 'stemi-generator.js' + res.sasToken
      this.loadScript(STEMIGeneratorJSFile)
        .then(() => {
          console.log('Script loaded successfully');
        })
        .catch(error => {
          console.error('Error loading script:', error);
        });
    })

    this._activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        this.recordingId = params.id;
        this.abstarctService.getReports().subscribe((res) => {
          res.forEach((x) => {
            if (x.entityId == this.recordingId) {
              this.recording = x
              this.stemiGenerator()
            }
          })
        })
      }
    });
  }

  loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Script load error for ${src}`));
      document.head.appendChild(script);
    });
  }

  goBack() {
    this._router.navigate(['/admin/reports/report-summary', this.recordingId]);
  }

  async stemiGenerator(): Promise<void> {
    const recording = await this.recordingData(this.recording)
    const summaryDetails = await this.getSummaryDetails()
    const ecgData = await this._getECGData();
    const organisationLogoDataURL = await this.getOrganizationLogo();
    const signatureDataURL = await this.getSignature();

    const stemiGenerator = await getDocumentDefinitionForSTEMI(recording, summaryDetails, ecgData, organisationLogoDataURL, signatureDataURL);
    const documentDefinition = stemiGenerator

    pdfMake.createPdf(documentDefinition).getDataUrl(async (res: any) => {

      const pdfContainer = document.querySelector('#pdf-summary-report') as HTMLEmbedElement;
      pdfContainer.src = res
    })
  }

  private async recordingData(recording: Report): Promise<any> {
    const recordingDTO = {
      id: recording?.entityId,
      patient: {
        id: recording?.patient?.entityId,
        patientFirstName: recording?.patient?.name,
        patientGender: recording?.patient?.gender,
      },
      referredBy: {
        id: recording?.referredById,
        firstName: recording?.referredBy?.name,
        qualification: recording?.referredBy?.qualification,
        lastName: ''
      },
      organisation: {
        id: recording?.organization?.entityId,
        firstName: recording?.organization?.name,
        phoneNumber: recording?.organization?.phoneNumber,
        countryCode: recording?.organization?.countryCode,
        city: recording?.organization?.city,
        state: recording?.organization?.state
      },
      fileName: recording?.fileName,
      createdAt: recording?.createdAt,
      age: recording?.patient?.age,
      ecgFindings: recording?.findings,
      interpretations: recording?.interpretation,
      recommendations: recording?.recommendation,
      addSignature: recording?.reportedBy?.addSignature,
      reportedById: recording?.reportedBy?.entityId,
      bpm: recording?.bpm,
      risk: recording?.risk,
      qrs: recording?.qrs,
      st: recording?.st,
      pr: recording?.pr,
      qt: recording?.qt,
      qtc: recording?.qtc,
      reportedBy: {
        id: recording?.reportedBy?.entityId,
        firstName: recording?.reportedBy?.name,
        qualification: recording?.reportedBy?.qualification,
        phoneNumber: recording?.reportedBy?.phoneNumber,
        countryCode: recording?.reportedBy?.countryCode,
        canPrintMCIId: recording?.reportedBy?.canPrintMCIId,
        mciNumber: recording?.reportedBy?.mciNumber
      },
      takenBy: {
        id: recording?.takenBy?.entityId,
        firstName: recording?.takenBy?.name,
        phoneNumber: recording?.takenBy?.phoneNumber,
        countryCode: recording?.takenBy?.countryCode
      },
      aiResponse: recording?.aiResponse,
      organisationPatientId: recording?.patient?.organisationPatientId,
      setup: recording?.setup ?? 'Standard',
      ecgDeviceVersion: recording?.deviceVersion ?? 'V2',
      referredByDoctorName: recording?.referredBy?.doctorName,
      referredByDoctorPhoneNumber: recording?.referredBy?.doctorPhoneNumber
    }

    return recordingDTO
  }

  private async getSummaryDetails(): Promise<Summary> {
    return new Promise((resolve, reject) => {
      this.abstarctService.getSummaryDetail(this.recordingId).subscribe((res) => {
        resolve(res)
      })
    })
  }

  private async _getECGData(): Promise<number[]> {
    return new Promise(async (resolve, reject) => {

      if (this.recording?.entityId && this.recording?.fileName) {
        this._tokenService
          .getReadTokenForECG(this.recording.fileName)
          .subscribe({
            next: (token: SASToken) => {
              if (token.sasToken) {
                this._blobService
                  .getECGRecording(this.recording?.fileName!, token.sasToken)
                  .subscribe({
                    next: (data: ArrayBuffer) => {
                      const rawECGData = [...new Uint8Array(data)];
                      resolve(rawECGData);
                    },
                    error: (e: any) => {
                      reject(e)
                    },
                  });
              }
            },
          });
      }
    })
  }

  private async getOrganizationLogo(): Promise<string> {
    return new Promise((resolve, reject) => {

      this._tokenService
        .getReadTokenForOrganizationLogo()
        .subscribe(async (token) => {
          if (token.sasToken) {
            const logoUrl =
              environment.azureBaseUrl +
              'profile-images/' +
              this.recording?.organization?.entityId +
              token.sasToken;

            const img = new Image();
            img.src = logoUrl;
            img.crossOrigin = 'anonymous';

            img.onload = async () => {
              resolve(logoUrl);
            };

            img.onerror = async () => {
              resolve(await this.getWellnestLogo())
            };
          }
        });
    })

  }

  private async getWellnestLogo(): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = 'assets/images/wellnestLogo.png';

      img.onload = () => {
        resolve(this.getDataUrl(img));
      };
    })
  }

  private async getSignature(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.recording?.reportedBy?.entityId && this.recording?.reportedBy?.addSignature == true) {
        let id = this.recording?.reportedBy?.entityId;
        this._tokenService
          .getReadTokenForSignature(id)
          .subscribe(async (token) => {
            const signatureUrl = environment.azureBaseUrl + 'signatures/' + id + token.sasToken;

            const img = new Image();
            img.crossOrigin = 'anonymous';

            img.onload = () => {
              resolve(signatureUrl);
            };

            img.onerror = async () => {
              resolve('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=');
            };
            img.src = signatureUrl;
          });
      } else {
        resolve('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=');
      }
    })

  }

  private async getDataUrl(img: HTMLImageElement) {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    // Copy the image contents to the canvas
    var ctx = canvas.getContext('2d');
    ctx!.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }
}